<template>
  <div>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card>
        <b-form-group
          label-cols="2"
          :label="$t('can_multi_bet')"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
              style="position:relative; text-align:left; margin-top: 6px"
            >
              <b-form-checkbox
                v-model="partner_info.canMulti"
                name="can-multi-bet"
                value="true"
                unchecked-value="false"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('id')"
          label-for="partner-info-id"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-id"
                v-model="partner_info.id"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('api_key')"
          label-for="partner-info-api-key"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-api-key"
                v-model="partner_info.apiKey"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('account')"
          label-for="partner-info-username"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-username"
                v-model="partner_info.username"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('person_in_charge')"
          label-for="partner-info-person-in-charge"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-person-in-charge"
                v-model="partner_info.personInCharge"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('contact_details')"
          label-for="partner-info-contact-details"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-contact-details"
                v-model="partner_info.contactNumber"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('telegram_id')"
          label-for="partner-info-telegram-id"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-telegram-id"
                v-model="partner_info.emailAdress"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('white_list_ips')"
          label-for="partner-info-white-list-ips"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-white-list-ips"
                v-model="partner_info.white_list_ips"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('logout_url')"
          label-for="partner-info-logout-url"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-logout-url"
                v-model="partner_info.logoutURL"
                autocomplete="off"
                maxlength="20"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="2"
          :label="$t('seamless_type')"
          label-for="partner-info-seamless-type"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-radio-group
                v-model="partner_info.seamless.type"
                :options="seamlessType"
                name="partner-info-seamless-type"
                style="padding-top: 8px;"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="partner_info.seamless.type === 'half'"
          label-cols="2"
          :label="$t('callback_url')"
          label-for="partner-info-callback-url"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-callback-url"
                v-model="partner_info.seamless.callbackUrl"
                autocomplete="off"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="partner_info.seamless.type === 'full'"
          label-cols="2"
          :label="$t('callback_balance')"
          label-for="partner-info-callback-balance"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-callback-balance"
                v-model="partner_info.seamless.callbackUrls.balance"
                autocomplete="off"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="partner_info.seamless.type === 'full'"
          label-cols="2"
          :label="$t('callback_bets')"
          label-for="partner-info-callback-bets"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-callback-bets"
                v-model="partner_info.seamless.callbackUrls.bets"
                autocomplete="off"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="partner_info.seamless.type === 'full'"
          label-cols="2"
          :label="$t('callback_results')"
          label-for="partner-info-callback-results"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-callback-results"
                v-model="partner_info.seamless.callbackUrls.results"
                autocomplete="off"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          v-if="partner_info.seamless.type === 'full'"
          label-cols="2"
          :label="$t('callback_cancel')"
          label-for="partner-info-callback-cancel"
          label-align-sm="right"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <b-form-input
                id="partner-info-callback-cancel"
                v-model="partner_info.seamless.callbackUrls.cancel"
                autocomplete="off"
                disabled
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
} from 'bootstrap-vue'

import Account from '@/models/Account'

import { EventBus } from '../../../eventbus'

export default {
  name: 'PartnerInf',
  components: {
    BOverlay,
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormRadioGroup,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      partner_info: {
        seamless: {
          type: '',
        },
      },
      seamlessType: [{
        text: 'Transafer',
        value: '',
      }, {
        text: 'Half',
        value: 'half',
      }, {
        text: 'Full',
        value: 'full',
      }],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await Account.getPartnerInfo()
      this.partner_info = response.data.data.partner
      if (typeof this.partner_info.seamless === 'undefined') {
        this.partner_info.seamless = {
          type: '',
        }
      }
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
  },
}
</script>
